
import { fetchKutas, fetchPublicUser } from '@/api/methods';
import { KutaItem, KutaSet, LngPairChartData } from '@/api/models/kuta-set';
import { Subject } from '@/api/models/subject';
import { Options, Vue } from 'vue-class-component';
import PersonForm from "./PersonForm.vue"
import ChartList from "./ChartList.vue"
import KutaTable from "./KutaTable.vue"
import { fromLocal, hasLocalObject, toLocal } from '@/api/localstore';
import { defaultInstructions, grahaDict, matchSignIcon } from '@/api/settings';
import { notEmptyString, validEmail } from '@/api/validators';
import { smartCastInt, zeroPad } from '@/api/converters';
import { Dictionary } from '@/api/models/lexeme';
import { Prop } from 'vue-property-decorator';
import { KeyName, KeyNameShort, KeyValue } from '@/api/interfaces';

@Options({
  components: {
    PersonForm,
    ChartList,
    KutaTable
  }
})
export default class KutaContainer extends Vue {
  @Prop({ default: () => new Dictionary() }) readonly dictionary: Dictionary = new Dictionary();

  main = new Subject();
  partner = new Subject();
  kutaSet = new KutaSet();
  changing = false;
  puid = '';
  currentRefNum = 1;
  pairs: LngPairChartData[] = [];
  chartData: LngPairChartData = new LngPairChartData();
  syncing = false;
  showList = false;
  gKey = "mo";
  lang = "en";
  alpha = "lt";
  identifier = "";
  newMode = false;

  ashtaKeys = [
    "varna",
    "vashya",
    "tara",
    "yoni",
    "grahamaitri",
    "gana",
    "rashi",
    "nadi"
  ];

  extraDvadashaKeys = ["rajju", "vedha", "stri", "mahendra"];

  dashaKeys = [
    'vashya/dasha',
    'tara',
    'yoni',
    'grahamaitri',
    'gana/dasha',
    'rashi',
    'rajju/dasha',
    'vedha',
    'mahendra',
    'stri',
  ];

  saptaKeys = [
    'varna/scale/5',
    'vashya',
    'tara/scale/6',
    'yoni',
    'grahamaitri/sapta',
    'gana/sapta',
    'rashi/scale/8',
  ];

  tables: KeyName[] = [
    {
      key: "ashtakuta",
      name: "Aṣṭhakūta"
    },
    {
      key: "dasha",
      name: "Daśakūta"
    },
    {
      key: "sapta",
      name: "Saptakūta"
    },
    {
      key: "dvakuta",
      name: "Dvadaśakūta"
    }
  ];

  openTables = [0, 1, 2, 3];

  created(): void {
    setTimeout(this.init, 250)
    this.emitter.on('update-chart', (ok: boolean) => {
      if (ok) {
        this.save();
      }
    });
    this.emitter.on('escape', (ok: boolean) => {
      if (ok) {
        this.showList = false;
      }
    });
    this.emitter.on('set-next-pair-num', (ok: boolean) => {
      if (ok) {
        const nums = this.pairs.map(p => p.num);
        let nextNum = 1;
        if (nums.length > 0) {
          const maxNum = Math.max(...nums);
          nextNum = maxNum + 1;
        }
        this.currentRefNum = nextNum;
        this.newMode = true;
      }  
    })
    this.emitter.on('load-pair', (key: string) => {
      const index = this.pairs.findIndex(p => p.key === key);
      if (index >= 0 && index < this.pairs.length) {
        const pairedItem = this.pairs[index];
        const { num } = pairedItem;
        this.currentRefNum = num;
        fetchKutas({email: this.identifier, pn: num}).then((result: any) => {
          this.assignResults(result);
          setTimeout(() => {
            this.emitter.emit('pair-loaded', this.chartData);
            this.showList = false;
            this.newMode = false;
          }, 250);
        })
      }
    });
    this.emitter.on('load-remote', (item: any) => {
      if (item instanceof Object) {
        const { email , context } = item;
        if (context === 'main' && validEmail(email) && !this.syncing) {
          if (!hasLocalObject('publicuser', ['identifier', '_id'])) {
            toLocal('publicuser', { identifier: email });
          }
          this.syncCharts();
        }
      }
    })
    this.emitter.on('delete-pair', (index: number) => {
      if (index >= 0 && index < this.pairs.length) {
        const refPair = this.pairs[index];
        if (refPair instanceof LngPairChartData) {
          if (this.currentRefNum === refPair.num) {
            this.resetForm();
            this.showList = true;
          }
          this.pairs.splice(index, 1);
        }
      }
    })
    setTimeout(() => {
      if (this.pairs.length < 1) {
        this.syncCharts();
      }
    }, 1000);
  }

  init(): void {
    const stored = fromLocal('chart-data', 7 * 24 * 60 * 60);
    if (!stored.expired) {
      this.assignResults(stored.data, false);
    }
  }

  get showTable(): boolean {
    return this.chartData.isValid && this.dictionary.lexemes.length > 10;
  }

  get saveLabel(): string {
    return this.newMode ? "Save new chart" : "Save details";
  }

  matchCurrentKeys(key = '') {
    switch (key) {
      case 'ashta':
      case 'ashtakuta':
        return this.ashtaKeys;
      case 'dvadasha':
      case 'dvakuta':
        return [...this.ashtaKeys, ...this.extraDvadashaKeys];
      case 'dasha':
        return this.dashaKeys;
      case 'sapta':
        return this.saptaKeys;
      default:
        return [];
    }
  }

  matchKutas(key = '') {
    switch (key) {
      case 'ashta':
      case 'ashtakuta':
        return this.ashtaKutas;
      case 'dvadasha':
      case 'dvakuta':
        return this.dvadashaKutas;
      case 'dasha':
        return this.dashaKutas;
      case 'sapta':
        return this.saptaKutas;
      default:
        return [];
    }
  }

  matchSortedKutas(key = '') {
    const keyRefs = this.matchCurrentKeys(key);
    const mappedKutas = this.matchKutas(key).map(kt => {
      return {
        kt,
        index: keyRefs.indexOf(kt.variantKey)
      }
    });
    mappedKutas.sort((a, b) => a.index - b.index);
    return mappedKutas.map(row => row.kt);
  }

  get tableData() {
    return this.tables.map((row, index) => {
      const {key, name} = row;
      const kutas = this.matchSortedKutas(key);
      const hasData = kutas.length > 3;
      const itemKey = ['tab', key, index].join('-');
      return {
        key,
        name,
        kutas,
        hasData,
        itemKey
      }
    }).filter(row => row.hasData)
  }

  get showInstructions(): boolean {
    return false;
  }

  get comboGrahaItems(): KeyNameShort[] {
    return this.grahaItems(0);
  }

  get ashtaKutas() {
    return this.kutas.filter(kt => this.ashtaKeys.includes(kt.variantKey));
  }

  get extraDvadashaKutas() {
    return this.kutas.filter(kt => this.extraDvadashaKeys.includes(kt.variantKey));
  }

  get dvadashaKutas() {
    return this.kutas.filter(kt => this.extraDvadashaKeys.includes(kt.variantKey) || this.ashtaKeys.includes(kt.variantKey));
  }

  get saptaKutas() {
    const plainKeys = this.saptaKeys.map((k:string) => k.split('/').shift()).map(k => k?.toString());
    return this.kutas.filter(kt => plainKeys.includes(kt.variantKey));
  }

  get dashaKutas() {
    const plainKeys = this.dashaKeys.map((k:string) => k.split('/').shift()).map(k => k?.toString());
    return this.kutas.filter(kt => plainKeys.includes(kt.variantKey));
  }

  get partner1(): string {
    return  this.chartData.isValid? this.chartData.p1.name : "";
  }

  get partner2(): string {
    return  this.chartData.isValid? this.chartData.p2.name : "";
  }

  get currentIndex() {
    const index = this.pairs.findIndex(p => p.num === this.currentRefNum);
    return index < 0 ? this.currentRefNum - 1 : index;
  }

  get pairRefs(): KeyValue[] {
    const refs = this.pairs.map((pair,pi) => {
      return {
        value: (pi + 1),
        key: pair.names,
        type: 'stored'
      };
    });
    refs.push({
      value: (refs.length + 1),
      key: "-",
      type: "new"
    });
    return refs;
  }

  isOpen(index = 0): boolean {
    return this.openTables.includes(index);
  }

  grahaItems(num = 1): KeyNameShort[] {
    const gks = ['su', 'mo', 've', 'as'];
    return grahaDict.filter(item => gks.includes(item.key)).map(item => {
      const itemKey = [['gk', num].join(''),item.key].join('-');
      const idKey = [['p', num].join(''),item.key].join('-');
      const classNames = [item.key];
      if (item.key == this.gKey) {
        classNames.push('selected');
      }
      return {
        ...item,
        itemKey,
        idKey,
        classNames
      }
    });
  }

  get wrapperClasses(): string[] {
    const cls = this.showInstructions? ['no-data'] : ['has-data'];
    if (this.showList) {
      cls.push('show-chart-list');
    }
    return cls;
  }

  get instructions(): string {
    return defaultInstructions.trim();
  }

  get p1Name() {
    return this.chartData.ps1.shortName;
  }

  get p2Name() {
    return this.chartData.ps2.shortName;
  }

  get total(): number {
    return this.pairs.length;
  }

  get hasPairs(): boolean {
    return this.pairs.length > 0;
  }

  get refNumTooltip(): string {
    return `Pair ${this.currentRefNum} of ${this.total}`;
  }

  get managePairsTip(): string {
    const plural = this.total === 1 ? "" : "s";
    return `Manage saved ${this.total} paired chart${plural}`;
  }

  unitName(strVal = "", showDiff = false) {
    const parts = strVal.split("/");
    const type = parts[0];
    //const lastIndex = parts.length-1;
    const numKey = parts.length > 1 ? parts[1] : "1";
    const modeKey = parts.length > 2 ? parts[2] : "";
    switch (type) {
      case 'nakshatra':
        return this.nakName(numKey, modeKey, showDiff);
      case 'sign':
        return this.signName(numKey);
      case 'varna':
      case 'yoni':
      case 'vashya':
      case 'nadi':
      case 'gana':
        return this.kutaUnitName(type, numKey);
      case 'dignity':
        return this.dignityName(numKey);
      case 'rajju':
        return this.rajjuName(numKey, modeKey);
      case 'tara':
        return this.taraName(numKey, modeKey);
      default:
        return strVal;
    }
  }

  kutaName(subKey = "") {
    const keyRef = [subKey, 0].join('_');
    const lex = this.dictionary.lexeme('kuta', keyRef)
    return lex.text(this.lang, "default", this.alpha)
  }

  dignityName(subKey = "") {
    const lex = this.dictionary.lexeme('dignity', subKey);
    return lex.text(this.lang, "default", this.alpha)
  }

  kutaUnitName(unitKey = "", numKey = "", zeroPadLen = 0) {
    const numRef = zeroPadLen > 0 ? zeroPad(numKey, zeroPadLen) : numKey;
    const keyRef = [unitKey, numRef].join('_');
    const lex = this.dictionary.lexeme('kuta', keyRef);
    return lex.text(this.lang, "default", this.alpha)
  }

  taraName(subKey = "", modeKey = "") {
    const parts = [this.kutaUnitName("tara", subKey)];
    if (notEmptyString(modeKey)) {
      parts.push(`[${modeKey}]`);
    }
    return parts.join(" ");
  }

  rajjuName(subKey = "", dirIcon = "") {
    return this.kutaUnitName("rajju", subKey) + " " + dirIcon;
  }

  signName(numKey = "") {
    const lex = this.dictionary.lexeme('rashi', numKey)
    const icon = matchSignIcon(parseInt(numKey));
    return icon + " " + lex.text(this.lang, "default", this.alpha)
  }

  nakName(numKey = "", modeKey = "", showDiff = false) {
    const keyRef = ['n27', zeroPad(numKey, 2)].join('_');
    const lex = this.dictionary.lexeme('nakshatra', keyRef);
    const parts = [lex.text(this.lang, "default", this.alpha)]
    if (showDiff && notEmptyString(modeKey)) {
      parts.push(`[${modeKey}]`);
    }
    return parts.join(" ");
  }
  
  updatePerson(data: any = null): void {
    if (data instanceof Object) {
      const { context, subject, puid, identifier } = data;
      if (subject instanceof Object) {
        if (context === 'partner') {
          this.partner = new Subject(subject);
        } else {
          this.main = new Subject(subject);
          if (validEmail(identifier)) {
            this.identifier = identifier;
          }
        }
      }
      if (notEmptyString(puid)) {
        this.puid = puid;
      }
    }
  }

  toggleChartList() {
    this.showList = this.hasPairs? !this.showList : false;
  }

  get resetTooltip(): string {
    return `Reset both forms to enter a new pair`;
  }

  resetForm(): void {
    this.emitter.emit('reset-form', 'main');
    this.emitter.emit('reset-form', 'partner');
  }

  syncCharts(): void {
    const stored = fromLocal('publicuser', 7 * 24 * 60 * 60);
    if (!stored.expired && !this.syncing) {
      this.syncing = true;
      fetchPublicUser(stored.data.identifier).then(result => {
        if (result.valid) {
          toLocal('publicuser', result);
          if (result.identifier) {
            this.identifier = result.identifier;
          }
          if (notEmptyString(result._id)) {
            this.puid = result._id;
          }
          if (result.miniCharts instanceof Array && result.kutas instanceof Array) {
            const refNumStr = notEmptyString(result.pcKey)? result.pcKey.split('_').pop() : '1'
            const refNum = smartCastInt(refNumStr);
            const refIndex = refNum - 1;
            this.pairs = result.miniCharts.map((pair: any, pi: number) => {
              const kts = refIndex === pi ? result.kutas : [];
              return new LngPairChartData(pair.p1, pair.p2, kts, pair.key);
            });
            const hasPairs = this.pairs instanceof Array && this.pairs.length > 0;
            let matchedIndex = hasPairs ? this.pairs.findIndex(pair => this.chartData.p1.jd === pair.p1.jd && this.chartData.p2.jd === pair.p2.jd && this.chartData.p1.geo.lat === pair.p1.geo.lat && this.chartData.p2.geo.lat === pair.p2.geo.lat) : -1;
            if (hasPairs && matchedIndex < 0) {
              matchedIndex = 0;
            }
            if (matchedIndex >= 0) {
              const pair = this.pairs[refIndex];
              this.currentRefNum = pair.num;
              if (this.kutas.length < 12) {
                this.assignResults(pair);
              }
              this.emitter.emit('sync-person', {
                data: pair.p1,
                context: 'main'
              });
              this.emitter.emit('sync-person', {
                data: pair.p2,
                context: 'partner'
              });
              toLocal('chart-data', this.chartData);
            }
          }
        }
        setTimeout(() => {
          this.syncing = false;
        }, 1000);
      })
    }
  }

  get kutas(): KutaItem[] {
    const row = this.chartData.kutas.find(r => r.k1 === this.gKey && r.k2 === this.gKey);
    const showDiff1 = !this.chartData.femaleFirst;
    const showDiff2 = this.chartData.femaleFirst;
    if (row instanceof Object) {
      return row.values.map(kt => {
        kt.name = this.kutaName(kt.key);
        kt.c1Label = this.unitName(kt.c1Value, showDiff1);
        kt.c2Label = this.unitName(kt.c2Value, showDiff2);
        return kt;
      });
    } else {
      return [];
    }
  }

  assignResults(result: any = null, store = false): void {
    if (result instanceof Object) {
      const { p1, p2, kutas, key } = result;
      if (p1 instanceof Object && p2 instanceof Object && kutas instanceof Array) {
        this.chartData = new LngPairChartData(p1, p2, kutas, key);
        if (store) {
          toLocal('chart-data', result);
          const newPair = new LngPairChartData(p1, p2, [], key);
          if (this.currentIndex < this.pairs.length) {
            this.pairs[this.currentIndex] = newPair;
          } else {
            this.pairs.push(newPair);
          }
          
        }
      }
    }
  }

  handleTabChange() {
     this.emitter.emit('escape', true);
  }

  save(): void {
    this.emitter.emit('save-person', 'both');
    setTimeout(() => {
      if (!this.changing) {
        const stored = fromLocal('publicuser', 7 * 24 * 60 * 60);
        this.puid = stored.expired ? '' : stored.data._id;
        this.changing = true;
        if (this.main.isValid && this.partner.isValid) {
          fetchKutas({
            dt1: this.main.utcDateStr,
            dt2: this.partner.utcDateStr,
            geo1: this.main.geo,
            geo2: this.partner.geo, 
            n1: this.main.name,
            n2: this.partner.name,
            g1: this.main.gender,
            g2: this.partner.gender,
            to1: this.main.tzOffset,
            to2: this.partner.tzOffset,
            pl1: this.main.placeName,
            pl2: this.partner.placeName,
            r1: this.main.roddenValue,
            r2: this.partner.roddenValue,
            e1: this.main.eventType,
            e2: this.partner.eventType,
            puid: this.puid,
            email: this.identifier,
            pn: this.currentRefNum
          }).then((result: any) => {
            this.assignResults(result, true);
            this.showList = false;
            setTimeout(() => {
              this.changing = false;
            }, 125);
          });
        }
      }
    }, 500);
    setTimeout(() => {
      this.changing = false;
    }, 5000);
  }

}
