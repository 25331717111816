import { shortenName, smartCastInt } from "../converters";
import { dateStringToJulianDate } from "../julian-date";
import { notEmptyString, validDateTimeString } from "../validators";
import { GeoLoc } from "./geo";

export class Subject {
  name = "";
  gender = "";
  roddenValue = 0;
  placeName = "";
  geo = new GeoLoc();
  eventType = "";
  dob = "";
  tzOffset = 0;
  tzInfo = "";

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        if (typeof v === "string") {
          switch (k) {
            case "name":
            case "gender":
            case "placeName":
            case "eventType":
            case "dob":
            case "tzInfo":
              this[k] = v;
              break;
          }
        } else if (typeof v === "number" && k === "tzOffset") {
          this.tzOffset = v;
        } else if (v instanceof Object && k === "geo") {
          this.geo = new GeoLoc(v);
        } else if (k === "roddenValue") {
          this.roddenValue = smartCastInt(v);
        }
      });
    }
  }

  get hasLocation() {
    return this.geo.lat !== 0 || this.geo.lng !== 0;
  }

  get hasDateTime() {
    return validDateTimeString(this.dob);
  }

  get isValid() {
    return this.hasLocation && this.hasDateTime && notEmptyString(this.name);
  }

  get utcDateStr() {
    const julDate = dateStringToJulianDate(this.dob, 0 - this.tzOffset);
    return julDate.toISOSimple();
  }

  get shortName() {
    return shortenName(this.name);
  }
}
