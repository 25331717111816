
import { Options, Vue } from 'vue-class-component';
import { smartCastFloat } from './api/converters';
import { fromLocal, toLocal } from './api/localstore';
import { fetchTerms } from './api/methods';
import { Dictionary } from './api/models/lexeme';
import KutaContainer from './components/KutaContainer.vue';

@Options({
  components: {
    KutaContainer,
  },
})
export default class App extends Vue {

  dictionary: Dictionary = new Dictionary();

  version = 0.12;

  created(): void {
    const stored = fromLocal('version');
    const version = stored.valid? smartCastFloat(stored.data) : 0.1;
    const refresh = this.version > version;
    fetchTerms(refresh).then(terms => {
      if (terms instanceof Array) {
        this.dictionary = new Dictionary(terms);
      }
    })
    toLocal('version', this.version);
    window.addEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(e: any = null): void {
    
    if (e instanceof KeyboardEvent) {
      const key = e.code.toLowerCase();
      switch (key) {
       case "escape":
          this.emitter.emit('escape', true);
          break;
      }
    }
    
  }

}
