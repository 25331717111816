import { smartCastFloat } from "../converters";
import { isNumeric } from "../validators";

export class SimpleGrahaSet {
  su = 0;
  ve = 0;
  ma = 0;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        switch (k) {
          case "su":
          case "ve":
          case "ma":
            this[k] = smartCastFloat(v);
            break;
        }
      });
    }
  }
}

export class GrahaSet extends SimpleGrahaSet {
  mo = 0;
  me = 0;
  ju = 0;
  sa = 0;
  ra = 0;
  ke = 0;
  ur = 0;
  ne = 0;
  pl = 0;
  as = 0;
  ds = 0;

  constructor(inData: any = null) {
    super(inData);
    if (inData instanceof Object) {
      Object.entries(inData).forEach(([k, v]) => {
        switch (k) {
          case "mo":
          case "me":
          case "ju":
          case "sa":
          case "ra":
          case "ke":
          case "ur":
          case "ne":
          case "pl":
          case "as":
          case "ds":
            this[k] = smartCastFloat(v);
            break;
        }
      });
    }
  }
}
