import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["data-value"]
const _hoisted_2 = ["data-value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createBlock(_component_DataTable, {
    value: _ctx.kutaItems,
    responsiveLayout: "scroll",
    class: _normalizeClass(_ctx.wrapperClasses)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "key",
        header: "Kuta",
        class: "text type",
        headerClass: "text type"
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "c1Value",
        header: _ctx.p1Name,
        class: "text value-name",
        headerClass: "text value-name"
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("span", {
            class: "value-cell",
            "data-value": slotProps.data.p1Value
          }, [
            _createTextVNode(_toDisplayString(slotProps.data.p1Label), 1)
          ], 8, _hoisted_1)), [
            [
              _directive_tooltip,
              slotProps.data.grahaRef1,
              void 0,
              { right: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "c2Value",
        header: _ctx.p2Name,
        class: "text value-name",
        headerClass: "text value-name"
      }, {
        body: _withCtx((slotProps) => [
          _withDirectives((_openBlock(), _createElementBlock("span", {
            class: "value-cell",
            "data-value": slotProps.data.p2Value
          }, [
            _createTextVNode(_toDisplayString(slotProps.data.p2Label), 1)
          ], 8, _hoisted_2)), [
            [
              _directive_tooltip,
              slotProps.data.grahaRef2,
              void 0,
              { right: true }
            ]
          ])
        ]),
        _: 1
      }, 8, ["header"]),
      _createVNode(_component_Column, {
        field: "score",
        header: "Score",
        class: "numeric score",
        headerClass: "numeric score"
      }, {
        footer: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.total), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "max",
        header: "Max",
        class: "numeric max",
        headerClass: "numeric max"
      }, {
        footer: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.maxTotal), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["value", "class"])), [
    [
      _directive_tooltip,
      _ctx.keyName.name,
      void 0,
      { bottom: true }
    ]
  ])
}