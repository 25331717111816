import { KeyNameShort } from "./interfaces";

export const grahaDict: KeyNameShort[] = [
  {
    key: "su",
    short: "☉",
    name: "Sun",
  },
  {
    key: "mo",
    short: "☽",
    name: "Moon",
  },
  {
    key: "ve",
    short: "♀",
    name: "Venus",
  },
  {
    key: "ma",
    short: "♂",
    name: "Mars",
  },
  {
    key: "as",
    short: "Asc.",
    name: "Ascendant",
  },
];

export const matchGrahaItem = (key: string): KeyNameShort => {
  const row = grahaDict.find((r) => r.key === key);
  return row instanceof Object ? row : { key, name: key, short: key };
};

export const matchGrahaName = (key: string, long = false): string => {
  const row = matchGrahaItem(key);
  return long ? row.name : row.short;
};

export const signIcons = [
  "♈︎",
  "♉︎",
  "♊︎",
  "♋︎",
  "♌︎",
  "♍︎",
  "♎︎",
  "♏︎",
  "♐︎",
  "♑︎",
  "♒︎",
  "♓︎",
];

export const matchSignIcon = (sign = 0) => {
  return sign > 0 && sign <= 12 ? signIcons[sign - 1] : "";
};

export const defaultGenderOptions = [
  {
    key: "f",
    title: "Female",
  },
  {
    key: "m",
    title: "Male",
  },
  {
    key: "o",
    title: "Other",
  },
];

export const defaultInstructions = `
  <h2>Kutas</h2>
  <h3>Instructions</h3>
  <ul>
    <li>Enter your name</li>
    <li>Set your date and time of birth</li>
    <li>Enter the nearest city or town to your place of birth and choose the best match from the suggestions to assign your birth coordinates and time zone.</li>
    <li>Do the same for your partner.</li>
    <li>Set the correct gender, event type and Rodden scale options</li>
    <li>Press save to reveal your matching kutas.</li>
  </ul>
`;

export const emptyLabels: string[] = [];
