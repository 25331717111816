
import { Options, Vue } from 'vue-class-component';
import { LngPairChartData } from '@/api/models/kuta-set';
import { Prop } from 'vue-property-decorator';
import { notEmptyString } from '@/api/validators';
import { removePaired } from '@/api/methods';

@Options({
  emits: ['update']
})
export default class ChartList extends Vue {
  @Prop({ default: () => [] }) pairs: LngPairChartData[] = [];
  @Prop({ default: 1 }) refNum = 1;
  @Prop({ default: "" }) puid = "";
  @Prop({ default: false }) updating = false;

  private selected = "";
  
  mounted(): void {
    this.emitter.emit('list-mounted', true);
  }

  get wrapperClasses(): string[] {
    const cls: string[] = [];
    return cls;
  }

  get showPairs(): boolean {
    return this.pairs.length > 0 && !this.updating;
  }

  selectItem(pairIndex = -1): void {
    if (pairIndex >= 0 && pairIndex < this.pairs.length) {
      const pair = this.pairs[pairIndex];
      if (pair instanceof LngPairChartData) {
        this.selected = pair.key;
        this.emitter.emit('load-pair', pair.key);
      }
      
    }
  }

  handleDeletePair(pair: LngPairChartData): void {
    this.selected = pair.key;
    this.$confirm.require({
        message: `Are you sure you want to delete the paired chart for ${pair.names}`,
        header: 'Confirm deletion',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.deletePair(pair)
        },
        reject: () => {
          this.selected = "";
        }
    });
  }

  deletePair(pair: LngPairChartData): void {
    const { key } = pair
    if (notEmptyString(key, 3)) {
      const pairIndex = this.pairs.findIndex(p => p.key === key);
      if (pairIndex >= 0) {
        this.emitter.emit('delete-pair', pairIndex);
        removePaired(this.puid, key);
      }
    }
  }

  matchItem(pairIndex = 0): boolean {
    if (pairIndex < this.pairs.length) {
      const item = this.pairs[pairIndex];
      if (item instanceof LngPairChartData) {
        return item.num === this.refNum;
      }
    }
    return false;
  }

  itemClasses(pairIndex = 0): string[] {
    const cls = [['num', pairIndex + 1].join('-')]
    if (this.matchItem(pairIndex)) {
      cls.push('selected');
    }
    return cls;
  }

}
