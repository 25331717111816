
import { KeyName } from '@/api/interfaces';
import { KutaItem } from '@/api/models/kuta-set';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const emptyKeyName = { key: "", name: "" };

@Options({
  components: {
  }
})
export default class KutaTable extends Vue {
  @Prop({ default: () => [] }) readonly kutaItems: KutaItem[] = [];
  @Prop({ default: emptyKeyName }) readonly keyName: KeyName = emptyKeyName;
  @Prop({ default: true }) readonly open: boolean = true
  @Prop({ default: "" }) readonly p1Name = "";
  @Prop({ default: "" }) readonly p2Name = "";

  get total(): number {
    return this.kutaItems.map(kt => kt.score).reduce((a, b) => a + b, 0);
  }

  get maxTotal(): number {
    return this.kutaItems.map(kt => kt.max).reduce((a, b) => a + b, 0);
  }

  get wrapperClasses() {
    const cls = this.open ? ['open'] : ['closed']
    cls.push(this.keyName.key);
    return cls;
  }

}
