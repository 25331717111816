import {
  degAsDms,
  degToSign,
  shortenName,
  smartCastFloat,
} from "../converters";
import { KeyValue } from "../interfaces";
import { julToDateParts } from "../julian-date";
import { matchGrahaItem, matchSignIcon } from "../settings";
import { notEmptyString } from "../validators";
import { GeoLoc } from "./geo";
import { GrahaSet } from "./graha-set";

export class KutaItem {
  key = "";
  name = "";
  head = "";
  c1Value = "";
  c2Value = "";
  c1Label = "";
  c2Label = "";
  score = 0;
  max = 0;
  variantRef = "";
  femaleFirst = true;

  constructor(inData: any = null, femaleFirst = true) {
    this.femaleFirst = femaleFirst;
    if (inData instanceof Object) {
      const { key, head, c1Value, c2Value, variantRef, score, max } = inData;
      if (typeof key === "string") {
        this.key = key;
        this.name = key;
      }
      if (typeof head === "string") {
        this.head = head;
      }
      if (typeof c1Value === "string") {
        this.c1Value = c1Value;
        this.c1Label = c1Value;
      }
      if (typeof c2Value === "string") {
        this.c2Value = c2Value;
        this.c2Label = c2Value;
      }
      if (typeof variantRef === "string") {
        this.variantRef = variantRef;
      }
      if (typeof score === "number") {
        this.score = score;
      }
      if (typeof max === "number") {
        this.max = max;
      }
    }
  }

  get displayType(): string {
    const str =
      this.c1Value.length > 1 && this.c1Value.includes("/") ? this.c1Value : "";
    const parts = str.length > 2 ? str.split("/") : [];
    return parts.length > 0 ? parts[0] : "";
  }

  get variantKey() {
    const parts = [this.key];
    if (notEmptyString(this.variantRef) && this.variantRef !== "standard") {
      parts.push(this.variantRef);
    }
    return parts.join("/");
  }

  get grahaRefs(): KeyValue[] {
    if (this.head.includes("/")) {
      return this.head.split("/").map((part) => {
        const [key, deg] = part.split(":");
        return {
          key,
          value: smartCastFloat(deg),
        };
      });
    } else {
      return [];
    }
  }

  get p1Value(): string {
    return this.femaleFirst ? this.c1Value : this.c2Value;
  }

  get p2Value(): string {
    return this.femaleFirst ? this.c2Value : this.c1Value;
  }

  get p1Label(): string {
    return this.femaleFirst ? this.c1Label : this.c2Label;
  }

  get p2Label(): string {
    return this.femaleFirst ? this.c2Label : this.c1Label;
  }

  get grahaKeys(): string[] {
    return this.grahaRefs.map((gr) => gr.key);
  }

  get lngs(): number[] {
    if (this.head.includes("/")) {
      return this.grahaRefs.map((gr) => gr.value);
    } else {
      return [];
    }
  }

  get lng1(): number {
    return this.lngs.length > 0 ? this.lngs[0] : -1;
  }

  get gk1(): string {
    return this.grahaRefs.length > 0 ? this.grahaRefs[0].key : "";
  }

  get gk2(): string {
    return this.grahaRefs.length > 1 ? this.grahaRefs[1].key : "";
  }

  get lng2(): number {
    return this.lngs.length > 1 ? this.lngs[1] : -1;
  }

  grahaRef(num = 1): string {
    const lng = num < 2 ? this.lng1 : this.lng2;
    const gk = num < 2 ? this.gk1 : this.gk2;
    const degStr = degAsDms(lng, "raw", 0);
    const gItem = matchGrahaItem(gk);
    const signIcon = matchSignIcon(degToSign(lng));
    return `${signIcon} ${degStr} (${gItem.short})`;
  }

  get grahaRef1(): string {
    return this.grahaRef(1);
  }

  get grahaRef2(): string {
    return this.grahaRef(2);
  }
}

export class KutaSet {
  k1 = "";
  k2 = "";
  values: KutaItem[] = [];
  femaleFirst = true;

  constructor(inData: any = null, femaleFirst = true) {
    this.femaleFirst = femaleFirst;
    if (inData instanceof Object) {
      const { k1, k2, values } = inData;
      if (values instanceof Array) {
        this.values = values.map((vl) => new KutaItem(vl, femaleFirst));
      }
      if (typeof k2 === "string") {
        this.k2 = k2;
      }
      if (typeof k1 === "string") {
        this.k1 = k1;
      }
    }
  }
}

export class LngChartData {
  jd = 0;
  birth: GrahaSet = new GrahaSet();
  ayanamsha = 0;
  geo: GeoLoc = new GeoLoc();
  name = "";
  gender = "";
  placeName = "";
  tzOffset = 0;
  eventType = "birth";
  roddenValue = 200;

  constructor(inData: any = null) {
    if (inData instanceof Object) {
      const {
        jd,
        geo,
        birth,
        ayanamsha,
        name,
        placeName,
        gender,
        tzOffset,
        eventType,
        roddenValue,
      } = inData;
      if (birth instanceof Object) {
        this.birth = new GrahaSet(birth);
      }
      if (typeof jd === "number") {
        this.jd = jd;
      }
      if (typeof tzOffset === "number") {
        this.tzOffset = tzOffset;
      }
      if (geo instanceof Object) {
        this.geo = new GeoLoc(geo);
      }
      if (typeof ayanamsha === "number") {
        this.ayanamsha = ayanamsha;
      }
      if (typeof name === "string") {
        this.name = name;
      }
      if (typeof placeName === "string") {
        this.placeName = placeName;
      }
      if (typeof gender === "string") {
        this.gender = gender;
      }
      if (typeof eventType === "string") {
        this.eventType = eventType;
      }
      if (typeof roddenValue === "number") {
        this.roddenValue = roddenValue;
      }
    }
  }

  get jDate() {
    return julToDateParts(this.jd, this.tzOffset);
  }

  get info(): string {
    return `${this.name} (${this.jDate.monthYear})`;
  }

  get shortName() {
    return shortenName(this.name);
  }

  extractCore(name = "") {
    return {
      jd: this.jd,
      name,
      geo: this.geo,
      ayanamsha: this.ayanamsha,
      birth: this.birth,
    };
  }

  get uniqueId() {
    return [this.jd, this.geo.lat, this.geo.lng].join("-");
  }
}

export class Relationship {
  type = "marriage";

  startYear?: number;
  endYear?: number;
  span?: number;
}

export class LngPairChartData {
  p1: LngChartData = new LngChartData();

  p2: LngChartData = new LngChartData();

  kutas: KutaSet[] = [];

  relationship = new Relationship();

  key = "";

  constructor(p1: any = null, p2: any = null, kutas: any[] = [], key = "") {
    if (p1 instanceof Object) {
      this.p1 = new LngChartData(p1);
    }
    if (p2 instanceof Object) {
      this.p2 = new LngChartData(p2);
    }
    this.setKutas(kutas);
    if (notEmptyString(key)) {
      this.key = key;
    }
  }

  get femaleFirst() {
    return this.p1.gender === "f";
  }

  get ps1(): LngChartData {
    return this.femaleFirst ? this.p1 : this.p2;
  }

  get ps2(): LngChartData {
    return this.femaleFirst ? this.p2 : this.p1;
  }

  get num(): number {
    let num = 0;
    if (notEmptyString(this.key) && /_\d+/.test(this.key)) {
      const parts = this.key.split("_");
      if (parts.length > 1) {
        num = parseInt(parts[parts.length - 1], 10);
      }
    }
    return num;
  }

  setKutas(kutas: any[] = []) {
    if (kutas instanceof Array && kutas.length > 0) {
      this.kutas = kutas.map((kt) => new KutaSet(kt, this.femaleFirst));
    }
    return this;
  }

  get isValid(): boolean {
    return true;
  }

  get names(): string {
    return [this.p1.name, this.p2.name].join(" ⇔ ");
  }

  get info(): string {
    return [this.p1.info, this.p2.info].join(" ⇔ ");
  }

  get minYear() {
    const years = [
      julToDateParts(this.p1.jd).year,
      julToDateParts(this.p2.jd).year,
    ];
    return Math.max(...years);
  }

  get uniqueId() {
    return [this.p1.uniqueId, this.p2.uniqueId].join("--");
  }

  extractCore(name1 = "", name2 = ""): any {
    const p1 = this.p1.extractCore(name1);
    const p2 = this.p2.extractCore(name2);
    return {
      p1,
      p2,
      relationship: { ...this.relationship },
    };
  }
}
