import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", {
    class: _normalizeClass(["chart-list", _ctx.wrapperClasses])
  }, [
    (_ctx.showPairs)
      ? (_openBlock(), _createElementBlock("ol", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pairs, (pair, pi) => {
            return (_openBlock(), _createElementBlock("li", {
              key: ['chart-pair', pi, pair.uniqueId].join('-'),
              class: _normalizeClass(["paired-item", _ctx.itemClasses(pi)])
            }, [
              _createElementVNode("span", {
                class: "name-info",
                onClick: ($event: any) => (_ctx.selectItem(pi))
              }, _toDisplayString(pair.info), 9, _hoisted_2),
              _createElementVNode("i", {
                class: "pi pi-trash delete",
                onClick: ($event: any) => (_ctx.handleDeletePair(pair))
              }, null, 8, _hoisted_3)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}